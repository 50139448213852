export class CropperOption {
  public url: string;
  public maxsize?: number;
  public title?: string;
  public uploadBtnName?: string;
  public uploadBtnClass?: string;
  public cancelBtnName?: string;
  public cancelBtnClass?: string;
  public applyBtnName?: string;
  public applyBtnClass?: string;
  public errorMsgs?: object;
  public fdName?: string;
  public aspectRatio?: number;
  public viewMode?: number; // 0, 1, 2, 3
}
